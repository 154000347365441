<template>
  <div class="page customers">
    <div class="roll_back_line flex justify-between">
      <div @click="goBack" class="inline-block flex w-1/3 justify-start items-center go_back">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 18L8 12L14 6" stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <div class="h-full label_roll_back">
          Trailers
        </div>
      </div>
    </div>

    <div class="title_line flex justify-between">
      <div class="inline-block flex w-1/3 justify-start items-center">
        <h1 class="text-left">{{ add_new ? 'Add new trailer' : 'Edit new trailer' }}</h1>
      </div>
    </div>

    <div class="form_body flex justify-between">
      <div class="w-1/5">
        <div class="form_label text-left py-2">
          <span class="py-2 px-4">
            Trailer details
          </span>
        </div>
      </div>

      <div class="w-4/5 block">
        <div class="w-full flex mb-4">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="name" class="block text-gray-700 mb-2 text-left input_label">
              Name <span>*</span>
            </label>
            <input
                @input="toggleSaveButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                @click="skipErrors('name')"
                v-model="form.name"
                type="text"
                placeholder="i.e 'MY lorry'">
            <p v-if="errors.name" class="text-red-500 text-xs italic text-left py-2">
              Username must be at least 3 characters long
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="trailer_id" class="block text-gray-700 mb-2 text-left input_label">
              Trailer ID <span>*</span>
            </label>
            <input
                @input="toggleSaveButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="trailer_id"
                @click="skipErrors('trailer_id')"
                v-model="form.trailer_id"
                type="text"
                placeholder="i.e '23232'">
          </div>
          <p v-if="errors.trailer_id" class="text-red-500 text-xs italic text-left py-2">
            This field is required
          </p>
        </div>

        <div class="w-full flex mb-4">
          <div class="form_row w-full md:w-1/4 p-4">
            <label for="height" class="block text-gray-700 mb-2 text-left input_label">
              Height <span>*</span>
            </label>
            <span class="input_span">
              <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="height"
                  @input="toggleSaveButton"
                  @click="skipErrors('height')"
                  v-model="form.height"
                  type="text"
                  placeholder="1.5">
              <span class="units">
                m
              </span>
            </span>
            <p v-if="errors.height" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
            <p v-if="errors.height_no_valid" class="text-red-500 text-xs italic text-left py-2">
              Please check this field
            </p>
          </div>

          <div class="form_row w-full md:w-1/4 p-4">
            <label for="width" class="block text-gray-700 mb-2 text-left input_label">
              Width <span>*</span>
            </label>
            <span class="input_span">
              <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="width"
                  @input="toggleSaveButton"
                  @click="skipErrors('width')"
                  v-model="form.width"
                  type="text"
                  placeholder="1.5">
              <span class="units">
                m
              </span>
            </span>
            <p v-if="errors.width" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
            <p v-if="errors.width_no_valid" class="text-red-500 text-xs italic text-left py-2">
              Please check this field
            </p>
          </div>

          <div class="form_row w-full md:w-1/4 p-4">
            <label for="length" class="block text-gray-700 mb-2 text-left input_label">
              Length <span>*</span>
            </label>
            <span class="input_span">
              <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="length"
                  @input="toggleSaveButton"
                  @click="skipErrors('length')"
                  v-model="form.length"
                  type="text"
                  placeholder="1.5">
              <span class="units">
                m
              </span>
            </span>
            <p v-if="errors.length" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
            <p v-if="errors.length_no_valid" class="text-red-500 text-xs italic text-left py-2">
              Please check this field
            </p>
          </div>

          <div class="form_row w-full md:w-1/4 p-4">
            <div class="w-full flex justify-start mb-2">
              <label class="text-gray-700  text-left input_label"
                     for="flexSwitchCheckDefault">
                QR code <span>*</span>
              </label>
            </div>

            <div class="w-full flex justify-start">
              <div class="flex justify-center">
                <div class="form-check form-switch">
                  <input
                      class="checked_input form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-gray-400 bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                      type="checkbox"
                      role="switch"
                      v-model="form.qr_code"
                      id="flexSwitchCheckDefault">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full flex justify-end items-center py-6 px-2">
          <button
              @click="openCancelModal"
              class="this_button mr-4 py-3 px-1 bg-gray-200 border border-gray-300">
            Cancel
          </button>

          <button
              @click="saveForm"
              :disabled="update_disable"
              :class="update_disable ? 'text-white bg-gray-400 border-gray-500' : 'text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom'"
              class="this_button py-3 px-1 border">
            Save
          </button>
        </div>
      </div>
    </div>

    <DeleteModal v-on:delete-select="yesSelect" ref="delete_modal">
      <template v-slot:message>
        <h2>
            <span class="grey_text">
                Are you sure that you want to close this page?
            </span>
        </h2>
      </template>

      <template v-slot:button>
        <div class="pt-6 flex w-full">
          <div class="w-1/2 justify-center">
            <button
                @click="$refs.delete_modal.deleteItem()"
                class="this_button_grey mr-3 py-4 px-4 border text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom">
              Yes
            </button>
          </div>

          <div class="w-1/2 justify-center">
            <button
                @click="$refs.delete_modal.closeModal()"
                class="this_button_grey mr-3 py-4 px-4 border text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom">
              Cancel
            </button>
          </div>
        </div>
      </template>
    </DeleteModal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {infoMessage} from "@/services/messages";
import DeleteModal from "@/components/DeleteModal";

export default {
  name: "AddEditTrailer",
  data() {
    return {
      user: null,
      trailer: null,
      update_disable: true,
      add_new: true,
      skip_validate: [
        'user_id',
        'qr_code',
      ],
      form: {
        name: null,
        trailer_id: null,
        height: null,
        width: null,
        length: null,
        qr_code: false,
        user_id: null,
      },
      errors: {
        name: false,
        trailer_id: false,
        height: false,
        width: false,
        length: false,
        height_no_valid: false,
        width_no_valid: false,
        length_no_valid: false,
      }
    }
  },
  methods: {
    ...mapMutations([]),
    goBack() {
      this.$router.push('/trailers');
    },
    getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    openCancelModal() {
      this.$refs.delete_modal.show = true;
    },
    toggleSaveButton() {
      for (let key in this.form) {
        if (!this.skip_validate.includes(key) && !this.form[key]) {
          this.update_disable = true;
          return;
        }
      }
      this.update_disable = false;
    },
    isNumeric(num){
      return !isNaN(num)
    },
    validationForm() {
      this.skipErrors();

      let empty_errors = true;

      if (!this.form.name || this.form.name.length < 3) {
        this.errors.name = true;
        empty_errors = false;
      }
      if (!this.form.trailer_id) {
        this.errors.trailer_id = true;
        empty_errors = false;
      }
      if (!this.form.height) {
        this.errors.height = true;
        empty_errors = false;
      }
      if (this.form.height && !this.isNumeric(this.form.height)) {
        this.errors.height_no_valid = true;
        empty_errors = false;
      }
      if (!this.form.width) {
        this.errors.width = true;
        empty_errors = false;
      }
      if (this.form.width && !this.isNumeric(this.form.width)) {
        this.errors.width_no_valid = true;
        empty_errors = false;
      }
      if (!this.form.length) {
        this.errors.length = true;
        empty_errors = false;
      }
      if (this.form.length && !this.isNumeric(this.form.length)) {
        this.errors.length_no_valid = true;
        empty_errors = false;
      }

      this.update_disable = !empty_errors;

      return empty_errors;
    },
    skipErrors(val = null) {
      if (val) {
        this.errors[val] = false;
      } else {
        for (let key in this.errors) {
          this.errors[key] = false;
        }
      }
    },
    saveForm() {
      if (this.validationForm()) {
        this.submitThisForm();
      }
    },
    async submitThisForm() {
      if (this.add_new) {
        await this.createNew();
      } else {
        await this.updateThis();
      }
    },
    async updateThis() {
      const res = await this.$http.postAuth(`${this.$http.apiUrl()}/trailers/${this.trailer.id}/update`, {body: this.form});

      if (res?.data?.err) {
        console.log(res?.data?.err);
        return infoMessage(
            'Trailer update error.',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      infoMessage(
          `Trailer updated successfully!`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.goBack();
    },
    async createNew() {
      try {
        await this.$http.postAuth(`${this.$http.apiUrl()}/trailers/create`, {body: this.form});
      } catch (e) {
        console.error(e);
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }
      infoMessage(
          `Trailer has been created!`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.goBack();
    },
    async checkAddOrEdit() {
      if (this.$route.params.id && this.$route.params.id != 0) {
        this.add_new = false;
        await this.updateThisItem();
      } else {
        this.add_new = true;
        await this.getUser();
        this.form.user_id = this.user.id;
      }
    },
    async updateThisItem() {
      const response = await this.$http.getAuth(`${this.$http.apiUrl()}/trailers/${this.$route.params.id}/trailer`);

      this.trailer = response?.data?.trailer || null;

      if (!this.trailer) {
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      this.form = this.trailer;
    },
    yesSelect() {
      this.goBack();
    },
  },
  async mounted() {
    await this.checkAddOrEdit();
  },
  created() {},
  computed: {
    ...mapGetters([
      'getTopBanner'
    ]),
  },
  components: {
    DeleteModal
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.item {
  padding-right: 25px;
}
.actions {
  &:hover, &:active, &:focus{
    .hidden {
      display: block;
    }
    span {
      &>* {
        cursor: pointer;
      }
      &:first-child {
        &>*{
          margin-left: auto;
        }
      }
    }
  }
}
.go_back {
  cursor: pointer;
}
.title_line {
  padding: 10px 15px 22px;
}
.roll_back_line {
  color: #6c757d;
  font-size: 1.17em;
  font-weight: 700;
  padding: 10px 15px;
  width: 95%;
  margin: auto;
}
.label_roll_back {
  padding-top: 3px;
}
.active {
  background: #FFF1CE;
  border-radius: 5px;
  color: #FFA800;
}
.form_body {
  width: 95%;
  padding: 30px 15px;
  margin: auto;
}
.form_label {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 600;
}
.input_label {
  color: #22292f;
  font-size: 1em;
  font-weight: 600;
  span {
    color: #28a745;
  }
}
.span_in_select {
  color: #6c757d !important;
}
.input_span {
  position: relative;
}
.units {
  position: absolute;
  top: 3px;
  right: 15px;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 64px;
}
.checked_input {
  width: 60px;
  height: 30px;
}
.form-check-input:checked {
  background-color: #28a745;
  border-color: #28a745;
}
</style>
